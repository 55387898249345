// External imports
import React, { useState } from 'react';
// global imports
import { ServiceType } from 'interfaces';
import { ClientContract } from 'api/interfaces';
// Local imports
import {
	ClientDataI,
	ServiceDataI,
	VehicleDataI,
	ServiceLocationsDataI,
	ServiceLocation,
	ServiceDetailsErrorMessages,
	ServiceLocationsErrorMessages,
} from '../types';
import { steps } from '../constants';

type SetValueAttribute = string | number | boolean | null;

interface DispatchFormContextProps {
	currentStep: number;
	setSelectedStep: (stepClicked: number) => void;
	setClientAttribute: (key: string, value?: SetValueAttribute) => void;
	client: ClientDataI;
	setVehicleAttribute: (key: string, value?: SetValueAttribute) => void;
	vehicle: VehicleDataI;
	setServiceAttribute: (key: string, value?: SetValueAttribute | ServiceType) => void;
	service: ServiceDataI;
	setLocationsAttribute: (key: string, value: ServiceLocation | string | null) => void;
	locations: ServiceLocationsDataI;
	setContractAttributes: (clientData: Partial<ClientDataI>, vehicleData: Partial<VehicleDataI>) => void;
	detailsErrors: ServiceDetailsErrorMessages | null;
	setDetailsErrors: (errors: ServiceDetailsErrorMessages | null) => void;
	locationsErrors: ServiceLocationsErrorMessages | null;
	setLocationsErrors: (errors: ServiceLocationsErrorMessages | null) => void;
	serviceTypes: ServiceType[];
	setServiceTypes: (serviceTypes: ServiceType[]) => void;
	vehicleMakes: string[];
	setVehicleMakes: (vehicleMajes: string[]) => void;
	vehicleModels: string[];
	setVehicleModels: (vehicleMajes: string[]) => void;
	contract?: ClientContract;
	setContract: (contract: ClientContract) => void;
	loading: boolean;
	setLoading: (loading: boolean) => void;
}

const clientInitialState: ClientDataI = {
	name: '',
	lastName: '',
	maidenName: '',
	phone: '',
	secondaryPhone: '',
	email: '',
	driverName: '',
};

const vehicleInitialState: VehicleDataI = {
	make: '',
	otherMake: '',
	model: '',
	otherModel: '',
	armorLevel: '',
	year: undefined,
	color: '',
	plate: '',
	kavakId: '',
	policyNumber: '',
	externalId: '',
	vin: '',
	type: '',
};

const serviceInitialState: ServiceDataI = {
	store: '',
	situation: null,
	carrierName: '',
	carrierPhone: '',
	receiverName: '',
	receiverPhone: '',
	comments: '',
	vip: false,
	visitType: '',
	noveltyType: '',
	city: '',
	date: '',
	time: '',
};

const locationsInitialState: ServiceLocationsDataI = {
	situation: null,
	destination: null,
	referenceAddress: null,
};

const DispatchFormContext = React.createContext({} as DispatchFormContextProps);

const FormContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
	const [client, setClient] = useState(clientInitialState);
	const [vehicle, setVehicle] = useState(vehicleInitialState);
	const [service, setService] = useState(serviceInitialState);
	const [locations, setLocations] = useState(locationsInitialState);
	const [currentStep, setCurrentStep] = useState(steps.SEARCH_CONTRACT);
	const [detailsErrors, setDetailsErrors] = useState<ServiceDetailsErrorMessages | null>(null);
	const [locationsErrors, setLocationsErrors] = useState<ServiceDetailsErrorMessages | null>(null);
	const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);
	const [vehicleMakes, setVehicleMakes] = useState<string[]>([]);
	const [vehicleModels, setVehicleModels] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	// Used to store the contract data without modifications
	const [contract, setContract] = useState<ClientContract>();

	const setSelectedStep = (stepClicked: number) => {
		setCurrentStep(stepClicked);
	};

	const setClientAttribute = (key: string, value?: SetValueAttribute) => {
		setClient({
			...client,
			[key]: value,
		});
	};

	const setVehicleAttribute = (key: string, value?: SetValueAttribute) => {
		setVehicle((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const setServiceAttribute = (key: string, value?: SetValueAttribute | ServiceType | boolean) => {
		setService({
			...service,
			[key]: value,
		});
	};

	const setLocationsAttribute = (key: string, value: ServiceLocation | string | null) => {
		setLocations({
			...locations,
			[key]: value,
		});
	};

	const setContractAttributes = (clientData: Partial<ClientDataI>, vehicleData: Partial<VehicleDataI>) => {
		setClient({ ...clientInitialState, ...clientData });
		setVehicle({ ...vehicleInitialState, ...vehicleData });
		setService({ ...serviceInitialState });
		setLocations({ ...locationsInitialState });
	};

	return (
		<DispatchFormContext.Provider
			value={{
				setSelectedStep,
				currentStep,
				setClientAttribute,
				client,
				setVehicleAttribute,
				vehicle,
				setServiceAttribute,
				service,
				setLocationsAttribute,
				locations,
				setContractAttributes,
				detailsErrors,
				setDetailsErrors,
				locationsErrors,
				setLocationsErrors,
				serviceTypes,
				setServiceTypes,
				vehicleMakes,
				setVehicleMakes,
				vehicleModels,
				setVehicleModels,
				contract,
				setContract,
				loading,
				setLoading,
			}}
		>
			{children}
		</DispatchFormContext.Provider>
	);
};

export { DispatchFormContext, FormContextProvider };
