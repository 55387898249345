import { VANTI } from 'utils/constants';

export enum FEATURE_FLAG {
	// Dashboard sections
	DASHBOARD_ACTIVE = 'dashboard_active',
	DASHBOARD_CANCELLED = 'dashboard_cancelled',
	DASHBOARD_SCHEDULED = 'dashboard_scheduled',
	DASHBOARD_FINISHED = 'dashboard_finished',
	DASHBOARD_BILLING = 'dashboard_billing',
	DASHBOARD_INVOICE = 'dashboard_invoice',
	DASHBOARD_OTHERSTATUS = 'dashboard_otherstatus',

	// Billing dashboard buttons
	DASHBOARD_BILLING__PREORDER_BUTTON = 'dashboard_billing__preorder_button',
	DASHBOARD_BILLING__REJECT_SERVICE_BUTTON = 'dashboard_billing__reject_service_button',
	DASHBOARD_BILLING__DOWNLOAD_REPORT_BUTTON = 'dashboard_billing__download_report_button',
	DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN = 'dashboard_billing__show_retention_breakdown',
	DASHBOARD_BILLING__SHOW_RETENTION_TOTAL = 'dashboard_billing__show_retention_total',
	DASHBOARD_BILLING__SHOW_GENERATE_INVOICES = 'dashboard_billing__show_generate_invoices',
	DASHBOARD_BILLING_INVOICE__SHOW_SOCIAL_REASONS_FILTER = 'dashboard_billing_invoice__show_social_reasons_filter',
	DASHBOARD_BILLING__SHOW_DIVISION_FILTER = 'dashboard_billing__show_division_filter',

	// Invoice dashboard buttons
	DASHBOARD_INVOICE__DOWNLOAD_REPORT_BUTTON = 'dashboard_invoice__download_report_button',
	DASHBOARD_INVOICE__DOWNLOAD_DATA_BUTTON = 'dashboard_invoice__download_data_button',
	DASHBOARD_INVOICE__PAY_MULTIPLE_INVOICES = 'dashboard_invoice__pay_multiple_invoices',

	// Service Detail
	SERVICE_DETAIL__ACTION_BUTTONS__FINISH = 'service_detail__action_buttons__finish',
	SERVICE_DETAIL__ACTION_BUTTONS__EDIT = 'service_detail__action_buttons__edit',
	SERVICE_DETAIL__ACTION_BUTTONS__CONFIRM = 'service_detail__action_buttons__confirm',
	SERVICE_DETAIL__ACTION_BUTTONS__REVIEW = 'service_detail__action_buttons__review',
	SERVICE_DETAIL__SERVICE_COST__SHOW = 'service_detail__service_cost__show',
	SERVICE_DETAIL__COPAGO_VALUE__SHOW = 'service_detail__copago_value__show',

	SERVICE_DETAIL__PREPAID_BUTTON = 'service_detail__prepaid_button',

	SERVICE_DETAIL__NOTES_SECTION__SHOW = 'service_detail__notes_section__show',
	SERVICE_DETAIL__NOTES_SECTION__WRITE = 'service_detail__notes_section__write',

	SERVICE_DETAIL__CONTACT_ATTEMPT__SHOW = 'service_detail__contact_attempt__show',

	SERVICE_DETAIL__USER_INFO__SHOW = 'service_detail__user_info__show',

	SERVICE_DETAIL__DRIVER_TIMESTAMP_INFO__SHOW = 'service_detail__driver_timestamp_info__show',

	// Dispatch page
	DISPATCH_PAGE = 'dispatch_page',

	// Invoice dashboard buttons
	INVOICE_DETAIL__PAY_BUTTON = 'invoice_details__pay_button',
	INVOICE_DETAIL__REJECT_BUTTON = 'invoice_details__reject_button',
	INVOICE_DETAIL__UPLOAD_XML = 'invoice_details__upload_xml',
}

/**
 * Only these flags are allowed by the admins. They are not included as criteria in the database to prevent errors.
 */
export const ALLOWED_FOR_ADMINS = [
	FEATURE_FLAG.DASHBOARD_ACTIVE,
	FEATURE_FLAG.DASHBOARD_CANCELLED,
	FEATURE_FLAG.DASHBOARD_FINISHED,
	FEATURE_FLAG.DASHBOARD_BILLING,
	FEATURE_FLAG.DASHBOARD_INVOICE,
	FEATURE_FLAG.INVOICE_DETAIL__PAY_BUTTON,
	FEATURE_FLAG.INVOICE_DETAIL__REJECT_BUTTON,
	FEATURE_FLAG.SERVICE_DETAIL__NOTES_SECTION__SHOW,
	FEATURE_FLAG.SERVICE_DETAIL__SERVICE_COST__SHOW,
	FEATURE_FLAG.SERVICE_DETAIL__USER_INFO__SHOW,
	FEATURE_FLAG.DASHBOARD_INVOICE__DOWNLOAD_REPORT_BUTTON,
	FEATURE_FLAG.DASHBOARD_INVOICE__PAY_MULTIPLE_INVOICES,
	FEATURE_FLAG.DASHBOARD_BILLING__DOWNLOAD_REPORT_BUTTON,
	FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN,
	FEATURE_FLAG.SERVICE_DETAIL__PREPAID_BUTTON,
	FEATURE_FLAG.DASHBOARD_BILLING__SHOW_GENERATE_INVOICES,
	FEATURE_FLAG.DASHBOARD_BILLING_INVOICE__SHOW_SOCIAL_REASONS_FILTER,
	FEATURE_FLAG.DASHBOARD_BILLING__SHOW_DIVISION_FILTER,
	FEATURE_FLAG.SERVICE_DETAIL__COPAGO_VALUE__SHOW,
	FEATURE_FLAG.SERVICE_DETAIL__DRIVER_TIMESTAMP_INFO__SHOW,
];

export const ACCOUNTS_CANNOT_WRITE_NOTES_WHEN_USER_IS_CLIENT = [VANTI];
