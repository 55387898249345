import React, { ReactNode, MouseEvent, useState } from 'react';
import './styles.scss';

interface ButtonProps {
	children: ReactNode;
	isLoading?: boolean;
	disabled?: boolean;
	onClick: (event: MouseEvent<HTMLButtonElement>) => void;
	variant?: 'primary' | 'outlined';
	disabledTooltip?: string;
}

const Button = ({ children, onClick, isLoading, disabled, variant, disabledTooltip }: ButtonProps) => {
	const [isTooltipVisible, setTooltipVisible] = useState(false);

	const buttonClass = `custom-button ${variant === 'primary' ? 'primary' : ''}`;

	const handleMouseEnter = () => {
		if (disabled && disabledTooltip) {
			setTooltipVisible(true);
		}
	};

	const handleMouseLeave = () => {
		if (disabled && disabledTooltip) {
			setTooltipVisible(false);
		}
	};
	return (
		<div className="button-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<button disabled={isLoading || disabled} className={buttonClass} onClick={onClick}>
				<span className={isLoading ? 'visibility-hidden' : ''}>{children}</span>
				{isLoading ? <div className="btn-loader" /> : null}
			</button>
			{disabled && disabledTooltip && isTooltipVisible && <div className="tooltip-button">{disabledTooltip}</div>}
		</div>
	);
};

export default Button;
