/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosResponse, AxiosStatic } from 'axios';
import {
	Params,
	UpsertEosServiceStatusRejectedParams,
	UpsertEosInvoiceStatusParams,
	SendInvoiceEmailParams,
	FeatureFlag,
	EOSResponse,
	InvoiceAttachment,
	CreateEosInvoiceParams,
	UpsertEosInvoicePaymentParams,
	Provider,
	User,
} from '../interfaces';
import { EOS_API } from '../utils/constants';
import { parseObjToQuery } from '../utils/Utils';
import HttpClient, { getAxiosConfig } from './HttpClient';
export default class EosClient extends HttpClient {
	constructor(axios: AxiosStatic = Axios) {
		super(axios, EOS_API);
	}

	async getEosService(serviceNumber: number): Promise<AxiosResponse> {
		return await this.get(`/api/v1/services/${serviceNumber}`);
	}
	async getEosServices(params: Params): Promise<AxiosResponse> {
		return await this.get(`/api/v1/services/${parseObjToQuery(params)}`);
	}

	async getEosInvoices(params: Params): Promise<AxiosResponse> {
		return await this.get(`/api/v1/invoices/${parseObjToQuery(params)}`);
	}

	async getEosInvoice(id: string): Promise<AxiosResponse> {
		return await this.get(`/api/v1/invoices/${id}`);
	}

	async getEosServicesAsACsv(params: Params): Promise<AxiosResponse> {
		const options: any = await getAxiosConfig();
		return await this.get(`/api/v1/services-file/${parseObjToQuery(params)}`, { ...options, responseType: 'blob' });
	}

	async getBillingReport(params: Params): Promise<AxiosResponse> {
		const options: any = await getAxiosConfig();
		return await this.get(`/api/v1/services/report/${parseObjToQuery(params)}`, { ...options, responseType: 'blob' });
	}

	async createEosInvoice(data: CreateEosInvoiceParams): Promise<AxiosResponse> {
		return await this.post('/api/v1/invoices', data);
	}

	async validateXmlOfEosInvoice(
		xml: string,
		branch: string,
		invoiceTotal: number,
		invoiceTaxTotal: number,
		provider: Provider,
		invoiceRetentionTotal: number,
		invoiceSubTotal: number
	): Promise<AxiosResponse> {
		return await this.post('/api/v1/invoices/validate-xml', {
			xml,
			branch,
			invoiceTotal,
			invoiceTaxTotal,
			provider,
			invoiceRetentionTotal,
			invoiceSubTotal,
		});
	}

	async getInvoicesAsAPdf(params: Params): Promise<AxiosResponse> {
		const options: any = await getAxiosConfig();
		return await this.get(`/api/v1/invoices.pdf/${parseObjToQuery(params)}`, { ...options, responseType: 'blob' });
	}

	async getInvoiceReport(params: Params): Promise<AxiosResponse> {
		const options: any = await getAxiosConfig();
		return await this.get(`/api/v1/invoices/report/${parseObjToQuery(params)}`, { ...options, responseType: 'blob' });
	}

	async sendInvoiceEmail(id: number, data: SendInvoiceEmailParams): Promise<AxiosResponse> {
		return await this.post(`/api/v1/invoices/${id}/email`, data);
	}

	async upsertEosInvoice(data: UpsertEosInvoiceStatusParams): Promise<AxiosResponse> {
		return await this.post('/api/v1/invoices/reject', data);
	}

	async upsertEosServiceStatusRejected(data: UpsertEosServiceStatusRejectedParams): Promise<AxiosResponse> {
		return await this.post(`/api/v1/services/${data.serviceNumber}/reject`, data);
	}

	async upsertEosServiceReview(serviceNumber: number, reviewed: boolean): Promise<AxiosResponse> {
		return await this.post(`/api/v1/services/${serviceNumber}/review`, { reviewed });
	}

	async upsertEosInvoicePayment(data: UpsertEosInvoicePaymentParams): Promise<AxiosResponse> {
		return await this.post('/api/v1/invoices/pay', data);
	}

	async getFeatureFlags(): Promise<AxiosResponse<EOSResponse<FeatureFlag[]>>> {
		return await this.get('/api/v1/feature-flags');
	}

	async createInvoiceAttachmentsReference(data: InvoiceAttachment): Promise<AxiosResponse> {
		return await this.post(`/api/v1/invoices/attachments`, data);
	}

	async removeInvoiceAttachmentReference(fileId: number): Promise<AxiosResponse> {
		return await this.delete(`/api/v1/invoices/attachments/${fileId}`);
	}

	async generateInvoices(branch: string, user: User): Promise<AxiosResponse> {
		return await this.post(`/api/v1/invoices/bulk-generate`, { branch, user });
	}

	async checkIfLastPeriodWasProcessed(branch: string): Promise<AxiosResponse> {
		return await this.get(`/api/v1/invoices-period-generation-log/check-last-period?branch=${branch}`);
	}
}
