import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './Dashboard';
import Reports from './Reports';
import Service from './Service';
import CreateService from './CreateService';
import Login from './Login';
import LoginOption from './Login/LoginOption';
import ResetPassword from './ResetPassword';
import MapView from './MapView';
import { Context } from '../context/Context';
import { AppContext } from '../interfaces';
import { AuthGuard } from '../utils/Guards';
import { fetchFeatureFlags } from '../utils/FeatureFlags/utils';
import { SkeletonTheme } from 'react-loading-skeleton';

// eslint-disable-next-line react/display-name
const authGuard = (component: JSX.Element) => (): JSX.Element => <AuthGuard component={component} />;

const ScreenRoot = (): JSX.Element => {
	const { dispatch } = useContext(Context) as AppContext;

	useEffect(() => {
		fetchFeatureFlags(dispatch);
	}, []);

	return (
		<SkeletonTheme baseColor="#313131" highlightColor="#525252">
			<Switch>
				<Route path="/clients/dispatch" component={authGuard(<CreateService />)} />
				<Route path="/services/:id" render={authGuard(<Service />)} />
				<Route path="/login/:userType" component={LoginOption} />
				<Route path="/login" component={Login} />
				<Route path="/reset/:userType/forgot-password" component={ResetPassword} />
				<Route path="/reset/:userType/:token" component={ResetPassword} />
				<Route path="/report" render={authGuard(<Reports />)} />
				<Route path="/map" render={authGuard(<MapView />)} />
				<Route path={['/', '/dashboard']} render={authGuard(<Dashboard />)} />
			</Switch>
		</SkeletonTheme>
	);
};

export default ScreenRoot;
