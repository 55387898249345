import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import cn from 'classnames';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import { useParams } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import { saveAs } from 'file-saver';
import { Dropdown } from 'primereact/dropdown';
import { Calendar, CalendarChangeParams } from 'primereact/calendar';

import ProviderCosts from 'components/Service/ProviderCosts';

import HeliosClient from '../../api/HeliosClient';
import Navbar from '../../components/Navbar';
import ServiceToolbar from '../../components/Service/ServiceToolbar';
import InputGroup from '../../components/Service/InputGroup';
import Files from '../../components/Service/Files';
import ServiceFooter from '../../components/Service/ServiceFooter';
import Map from '../../components/Map';
import {
	isServiceVial,
	getBranchCode,
	isPetSituation,
	getServiceIcon,
	fetchService,
	fetchDriversByProviderId,
} from './Service.util';
import { Context } from '../../context/Context';
import {
	AppContext,
	Driver,
	EditServiceData,
	EosService,
	IBranchConfig,
	ProviderRetentionNames,
	Service,
} from '../../interfaces';
import {
	SURA_ID,
	INSPECTION_LINK,
	ROLE,
	USER_TYPE,
	BRANCH,
	DASHBOARD_SECTION,
	GALLERY_MAX_QUANTITY_FILES,
	HOUR_FORMAT,
	HELIOS_SERVICE_STATUS,
} from '../../utils/constants';
import { centsToCurrency, downloadS3Pdf, FileTypeError, getExtension, getSituationType } from '../../utils/Utils';
import ConnectServicesClient from '../../api/ConnectServicesClient';
import { fileErrorMessage } from '../../components/Commons/FileUploader.utils';
import ActionButtons from '../../components/Service/ActionButtons';
import CustomInput from '../../components/Service/CustomInput';
import { transformDateToISOString } from '../../utils/datetime.utils';

import CarImage from '../../assets/imgs/car.svg';
import InspectionsIcon from '../../assets/imgs/dashboard-icons/inspecciones_icon_off.png';
import UserIcon from '../../assets/imgs/user.svg';
import PdfImage from '../../assets/imgs/pdf.svg';
import CurrencyInput from '../../components/Service/CurrencyInput';

import './styles.scss';
import './bootstrap.min.css';
import Feature from '../../utils/FeatureFlags';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import EosClient from '../../api/EosClient';
import ButtonReview from './ButtonReview';

interface RouteParams {
	id: string;
}

interface ServiceImages {
	url: string;
	name: string;
	type: string;
}

interface ServiceImagesResponse {
	url?: string;
	name?: string;
	imageUrl?: string;
	imageName?: string;
}

const LIBRARIES = ['places'];
const MIN_KM_PS = 10;

const ServiceView: FunctionComponent = (): JSX.Element => {
	const { status, provider, user, userType, situationTypes } = useContext(Context) as AppContext;
	const [loading, setLoading] = useState<boolean>(true);
	const [service, setService] = useState({} as Service);
	const [eosService, setEosService] = useState({} as EosService);
	const [mapVisible, setMapVisible] = useState(true);
	const [errorMessage, setErrorMessage] = useState<string | null>();
	const [imagesLoaded, setImagesLoaded] = useState<boolean>(false);
	const [serviceImages, setServicesImages] = useState<Array<ServiceImages>>([]);
	const [handleFileLoading, setHandleFileLoading] = useState(false);
	const [driversByProvider, setDriversByProvider] = useState<Driver[]>([]);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [editServiceData, setEditServiceData] = useState<EditServiceData>({} as EditServiceData);
	const [branchConfigs, setBranchConfigs] = useState<IBranchConfig>({} as IBranchConfig);

	const adminRole = user?.roles.find((role: string) => role === ROLE.ADMIN_EOS) || null;
	const branch = provider?.branch || user?.branch;
	const params = useParams<RouteParams>();

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY as any,
		preventGoogleFontsLoading: true,
		libraries: LIBRARIES as any,
	});

	useEffect(() => {
		let timer: NodeJS.Timeout;

		const startInterval = () => {
			timer = setInterval(async () => {
				if (!editMode) {
					callToFetchService();
				}
			}, 10000); // 10 seconds
		};

		startInterval();
		return () => clearInterval(timer);
	}, [editMode]);

	useEffect(() => {
		async function initService() {
			setLoading(true);
			await callToFetchService();
			setLoading(false);
		}

		initService();
	}, [params.id]);

	useEffect(() => {
		if (provider?._id) {
			fetchDriversByProviderId({ providerId: provider._id, setDriversByProvider }).catch((error) => {
				toast.error(error.message);
			});
		}
	}, [provider?._id]);

	useEffect(() => {
		if (!service.serviceNumber || imagesLoaded) return;
		fetchImages();
		setImagesLoaded(true);
	}, [service]);

	useEffect(() => {
		if (service?.serviceNumber) {
			getEosService();
			getBranchConfigs();
		}
	}, [service?.serviceNumber]);

	const getEosService = async () => {
		const serviceResponse = await new EosClient().getEosService(service.serviceNumber);
		if (serviceResponse.data?.success) {
			setEosService(serviceResponse.data.data);
		}
	};

	const callToFetchService = async () =>
		await fetchService(params.id, setMapVisible, setService, setErrorMessage, setLoading);

	const fetchImages = async () => {
		let response = null;
		if (service.sfIdAccount === SURA_ID) {
			response = await new ConnectServicesClient().getSuraImages(service.serviceNumber);
		} else {
			response = await new ConnectServicesClient().getImages(service.serviceNumber);
		}
		const {
			data: { data },
		} = response;
		setServicesImages(
			data.map((image: ServiceImagesResponse) => ({
				url: image.url || image.imageUrl,
				name: image.name || image.imageName,
				type: 'service',
			}))
		);
	};

	const handleFile = async (files: File[], errors: FileTypeError[]) => {
		try {
			setHandleFileLoading(true);
			if (errors.length > 0) {
				const errorMessage = fileErrorMessage(errors);
				throw new Error(errorMessage);
			}
			if (files.length + serviceImages.length > GALLERY_MAX_QUANTITY_FILES) {
				throw new Error(`Exceded the maximum number of files: ${GALLERY_MAX_QUANTITY_FILES}`);
			}
			const folderRoot = service.serviceNumber.toString();
			for (const file of files) {
				const fileName = file.name;
				const fileExtension = getExtension(file.name);
				await new ConnectServicesClient().upload({
					folderRoot,
					fileName,
					fileExtension,
					file,
				});
			}
			fetchImages();
			toast.success('Files uploaded successfully');
		} catch (error: Error | any) {
			toast.error(error.message);
		} finally {
			setHandleFileLoading(false);
		}
	};

	const onToggleAjusteExpress = (ajusteExpress: boolean): void => {
		new HeliosClient().toggleAjusteExpress(
			{
				serviceId: service._id,
				ajusteExpress: ajusteExpress,
				employeeId: user._id,
				userType: userType,
			},
			userType as string
		);
	};

	const hasVirtualInspector = () => {
		const VIRTUAL_INSPECTOR = ['a221Y00000C0B85QAF', 'a221Y00000C0GviQAF', 'a221Y00000C0GvnQAF'];
		return VIRTUAL_INSPECTOR.includes(service.provider.salesforce);
	};

	const openInspectionForm = (): void => {
		if (hasVirtualInspector()) return;

		if (service.contactAccount && service.contactAccount.config && service.contactAccount.config.formAccess === false) {
			toast.info('This account has no inspection form');
		} else {
			const url = `${INSPECTION_LINK}${getBranchCode(service.branch)}/InspectForm?eventid=${service.inspection}`;
			window.open(service.formUrl || url, '_blank');
		}
	};

	const handleFileDownload = () => {
		if (!serviceImages) return;
		for (const image of serviceImages) {
			fetch(image.url, { headers: { 'Cache-Control': 'no-cache' } })
				.then((response) => response.blob())
				.then((blob) => {
					const url = URL.createObjectURL(blob);
					saveAs(url, image.name);
				});
		}
	};

	const fullName = `${service.firstname || ''} ${service.lastname || ''}`;

	const onChangeScheduleDate = (event: CalendarChangeParams) => {
		const scheduleDate = transformDateToISOString(String(event.value));
		if (scheduleDate) setEditServiceData({ ...editServiceData, schedule: scheduleDate });
	};

	const getScheduleCalendarValue = editServiceData?.schedule
		? dayjs(editServiceData.schedule).toDate()
		: service?.schedule
		? dayjs(service.schedule).toDate()
		: undefined;

	const getServiceCostLabel = () => {
		if (!service.serviceCost) {
			return centsToCurrency(0, service.branch as BRANCH);
		}
		const taxes = service.providerPayment?.taxes || [];
		const serviceCostTax = taxes.find((tax) => tax.name === 'iva')?.value || 0;
		const retention =
			service.providerPayment?.retentions?.find(
				(retention) => retention.name === ProviderRetentionNames.SITUATION_RETENTION
			)?.value ?? 0;
		const serviceCost = (service.serviceCost || 0) * 100;

		const serviceCostWithTax = serviceCost + serviceCostTax;
		const serviceCostWithTaxAndRetention = serviceCostWithTax - retention;

		const serviceCostLabel = centsToCurrency(serviceCostWithTaxAndRetention, service.branch as BRANCH);
		const serviceCostTaxLabel = centsToCurrency(serviceCostTax, service.branch as BRANCH);
		const retentionLabel = centsToCurrency(retention, service.branch as BRANCH);
		const retentionLabelPrefix = retention ? '-' : '';
		const situationLabel = getSituationType(service?.situation, situationTypes);
		return service.status === HELIOS_SERVICE_STATUS.FINISHED
			? `${serviceCostLabel} (Tax: ${serviceCostTaxLabel}) (Retention ${situationLabel}: ${retentionLabelPrefix}${retentionLabel})`
			: serviceCostLabel;
	};

	const getProviderPaymentTotalLabel = () => {
		if (!service.providerPaymentTotal) return 'N/A';

		const tax = centsToCurrency(service.providerPayment?.taxesTotal || 0, service.branch as BRANCH);
		const taxLabel = tax ? `(Tax: ${tax})` : '';

		const providerPaymentTotal = centsToCurrency(service.providerPaymentTotal || 0, service.branch as BRANCH);

		const retention =
			service?.providerPayment?.retentions?.find(
				(retention) => retention.name === ProviderRetentionNames.PROVIDER_RETENTION
			)?.value ?? 0;
		const retentionLabel = retention ? `(Retention PP: -${centsToCurrency(retention, service.branch as BRANCH)})` : '';

		return `${providerPaymentTotal} ${taxLabel} ${retentionLabel}`;
	};

	const getBranchConfigs = async () => {
		try {
			const {
				data: { data: branchConfig },
			} = await new HeliosClient().getBranchConfigs(service.branch);
			setBranchConfigs(branchConfig);
		} catch (error) {
			console.log(error);
			toast.info('Error fetching Branch Configs');
		}
	};

	return (
		<div className="service-view">
			<Navbar />
			{!loading && errorMessage && <h1>{errorMessage}</h1>}
			{!loading && !errorMessage && (
				<>
					<ServiceToolbar serviceNumber={service.serviceNumber} serviceStatus={service.status} />
					<div className="container-fluid">
						<div className="row mt-3">
							<div className="col-sm-12 col-md-7 service-view__vr-divider">
								<Feature name={FEATURE_FLAG.SERVICE_DETAIL__USER_INFO__SHOW}>
									<div className="row mt-3">
										<div className="col">
											<img className="service-view__icon" src={UserIcon} alt="User icon" />
											<hr className="divider" />
											<div className="row sm-gutters">
												<InputGroup colSize="col-6" label="Account" value={service.account} />
												<InputGroup colSize="col-6" label="Name" value={fullName} />
											</div>
											<div className="row sm-gutters">
												<InputGroup colSize="col-6" label="Phone" value={service.phone1} />
												<InputGroup colSize="col-6" label="Email" value={service.email} />
											</div>
											<div className="row sm-gutters">
												<InputGroup
													colSize="col-6"
													label="Policy"
													value={
														service && service.insurance
															? service.insurance.policyNumber
															: service
															? service.policyNumber
															: ''
													}
												/>
												<InputGroup
													colSize="col-6"
													label="PIN"
													value={
														service && service.insurance
															? service.insurance.policyType
															: service
															? service.policyType
															: ''
													}
												/>
											</div>
										</div>
									</div>
								</Feature>
								{userType === USER_TYPE.PROVIDER && (
									<div className="row mt-3">
										<div className="col">
											{service.typeOfProperty && service.numberOfRooms && (
												<div className="row sm-gutters">
													<InputGroup colSize="col-6" label="Type of Property" value={service.typeOfProperty} />
													<InputGroup colSize="col-6" label="Number of Rooms" value={service.numberOfRooms} />
												</div>
											)}
										</div>
									</div>
								)}

								{!isPetSituation(service.situation, service.account) && (
									<div className="row mt-3">
										<div className="col">
											<img className="service-view__icon" src={CarImage} alt="Car icon" />
											<hr className="divider" />
											<div className="row sm-gutters">
												<InputGroup colSize="col-4" label="Make" value={service.make} />
												<InputGroup colSize="col-4" label="Model" value={service.model} />
												<InputGroup colSize="col-4" label="Year" value={service.year} />
											</div>
											<div className="row sm-gutters">
												<InputGroup colSize="col-4" label="Color" value={service.color} />
												<InputGroup colSize="col-4" label="Plate" value={service.plate || ''} />
												<InputGroup colSize="col-4" label="VIN" value={service.vin || ''} />
												<InputGroup colSize="col-4" label="Vehicle Type" value={service.vehicle?.type || ''} />
											</div>
										</div>
									</div>
								)}

								{isPetSituation(service.situation, service.account) && (
									<div className="row mt-3">
										<div className="col">
											<hr className="divider" />
											<div className="row sm-gutters">
												<InputGroup colSize="col-6" label="Pet Name" value={service.petName} />
												<InputGroup colSize="col-6" label="Pet Breed" value={service.petBreed} />
											</div>
											<div className="row sm-gutters">
												<InputGroup colSize="col-6" label="Pet Weight" value={service.petWeight + ' kg'} />
												<InputGroup colSize="col-6" label="Pet Age" value={service.petAge} />
											</div>
										</div>
									</div>
								)}

								<div className="row mt-3">
									<div className="col">
										<div className="flex-container">
											<span>
												<img
													className="service-view__icon"
													src={getServiceIcon(service)}
													alt="Situation Icon"
													title={getSituationType(service.situation, situationTypes)}
												/>
												<label className="service-view__label mx-1">
													{getSituationType(service.situation, situationTypes)}
												</label>
											</span>
										</div>
										<hr className="divider" />

										{userType !== USER_TYPE.USER && (
											<div className="row sm-gutters">
												<InputGroup colSize="col-4" label="Provider Name" value={service.providerName} />
												<InputGroup colSize="col-4" label="Provider Phone" value={service.providerPhone} />
												<CustomInput colSize="col-4" label="Driver / Store" editMode={editMode}>
													<Dropdown
														valueTemplate={editServiceData?.driver?.name ?? service?.driver?.name ?? 'N/A'}
														options={driversByProvider}
														onChange={(e) => setEditServiceData({ ...editServiceData, driver: e.value })}
														optionLabel="name"
														placeholder="Select store"
														className={cn('currency-input mx-2', { 'edit-mode': editMode })}
														disabled={!editMode}
													/>
												</CustomInput>
											</div>
										)}

										<div className="row sm-gutters">
											<InputGroup
												colSize="col-9"
												label="Situation Address"
												value={service.pinSituationAddress || service.pinStartRoadAddress}
											/>

											{branch !== BRANCH.MEX && service?.providerPayment?.billableDistance && (
												<InputGroup
													colSize="col-3"
													label="Billed Distance"
													value={
														branch === BRANCH.PRI
															? `${service?.providerPayment?.billableDistance} mi`
															: `${service?.providerPayment?.billableDistance} km`
													}
												>
													<div className="col-3">
														<a className="billed-distance-a" id="billed-distance-tooltip">
															ⓘ
														</a>
														<Tooltip
															className="billed-distance-tooltip"
															anchorSelect="#billed-distance-tooltip"
															content="Distancia facturable"
														/>
													</div>
												</InputGroup>
											)}

											<InputGroup
												colSize="col-9"
												label="Reference Address"
												value={service.locations?.situation?.referenceAddress}
											/>

											{!isServiceVial(service.situation) && (
												<InputGroup
													colSize="col-9"
													label="Destination Address"
													value={service.pinFinishRoadAddress || ''}
												/>
											)}

											<CustomInput colSize="col-5" label="Date / Time" editMode={editMode}>
												<Calendar
													appendTo="self"
													baseZIndex={99999}
													className={cn(
														'currency-input mx-2',
														{ 'currency-input__edit-mode': editMode },
														{ 'p-disabled': !editMode }
													)}
													dateFormat="dd-mm-yy"
													hourFormat={HOUR_FORMAT.TWELVE_HOUT_FORMAT}
													minDate={dayjs().subtract(1, 'day').toDate()}
													onChange={onChangeScheduleDate}
													readOnlyInput
													showOnFocus={editMode}
													showTime
													value={getScheduleCalendarValue}
												></Calendar>
											</CustomInput>

											<Feature name={FEATURE_FLAG.SERVICE_DETAIL__SERVICE_COST__SHOW}>
												{editMode ? (
													<CustomInput label="Cost" colSize="col-6" editMode={editMode}>
														<CurrencyInput
															value={service.serviceCost}
															onChange={(value: number) =>
																setEditServiceData({ ...editServiceData, serviceCost: value })
															}
															editMode={editMode}
															branch={service.branch}
															className={cn('currency-input mx-2', { 'currency-input__edit-mode': editMode })}
														/>
													</CustomInput>
												) : (
													<InputGroup colSize="col-8" label="Cost" value={getServiceCostLabel()} />
												)}
											</Feature>
											{status === DASHBOARD_SECTION.BILLING && (
												<>
													<InputGroup colSize="col-6" label="Provider Payment" value={getProviderPaymentTotalLabel()} />
													<Feature name={FEATURE_FLAG.SERVICE_DETAIL__PREPAID_BUTTON}>
														<InputGroup colSize="col-3" label="Prepaid">
															<div className="prepaid-switch">
																<Toggle icons={false} disabled={true} defaultChecked={service.isPrepaid} />
															</div>
														</InputGroup>
													</Feature>
												</>
											)}
										</div>

										<div className="row sm-gutters">
											<InputGroup colSize="col-4" label="Weight" value={service?.provider?.weightType} />
											<Feature name={FEATURE_FLAG.SERVICE_DETAIL__COPAGO_VALUE__SHOW}>
												<InputGroup
													colSize="col-4"
													label="Copago"
													value={centsToCurrency(service.copagoValue || 0, service.branch as BRANCH)}
												/>
											</Feature>
										</div>

										<div className="row sm-gutters">
											<ProviderCosts
												providerCosts={service?.providerPayment?.costs}
												branch={service.branch as BRANCH}
												status={service.status}
												branchConfig={branchConfigs}
											/>
										</div>

										{service?.files?.others?.length > 0 && userType === USER_TYPE.PROVIDER && (
											<Files files={service.files.others} />
										)}
									</div>
								</div>
								{service.sfIdAccount === SURA_ID && service.situation === 'inspections' && (
									<div className="service-view__inspections">
										<a hidden={service.situation !== 'inspections'}>
											<img className="icon-pointer" src={InspectionsIcon} width="35" onClick={openInspectionForm} />
										</a>
										{service.sfIdAccount === SURA_ID && service.situation === 'inspections' && (
											<div className="switch-container">
												<Toggle
													onChange={(): void =>
														onToggleAjusteExpress(!(service.external && service.external.ajusteExpress))
													}
													icons={false}
													disabled={userType !== USER_TYPE.USER}
													defaultChecked={service.external && service.external.ajusteExpress}
												/>
												<span> Ajuste Express</span>
											</div>
										)}
									</div>
								)}

								{service.pdfReportUrl && (
									<div className="service-view__pdf-report">
										<span>Control Event</span>
										<button onClick={() => downloadS3Pdf(service.pdfReportUrl as string)} className="btn">
											<img width="50" src={PdfImage} alt="s3 download" />
										</button>
									</div>
								)}
								<div className="row mx-1 my-3">
									<ActionButtons
										service={service}
										editServiceData={editServiceData}
										clearEditServiceData={() => setEditServiceData({} as EditServiceData)}
										fetchService={callToFetchService}
										setEditMode={(option: boolean) => setEditMode(option)}
										editMode={editMode}
									/>
								</div>
							</div>
							{!loading && (
								<div className="col-12 col-md-5">
									{!mapVisible && (
										<div className="col-12 service-view__map-no-visible">
											<p>The Map is Not Available for Inspections in Hold</p>
										</div>
									)}
									<Map hidden={!mapVisible} mapLoaded={isLoaded} service={service} />
									<ServiceFooter
										service={service}
										eosService={eosService}
										serviceImages={serviceImages}
										handleFile={handleFile}
										handleFileLoading={handleFileLoading}
										handleFileDownload={handleFileDownload}
									/>
								</div>
							)}
						</div>
					</div>
					<Feature name={FEATURE_FLAG.SERVICE_DETAIL__ACTION_BUTTONS__REVIEW}>
						<ButtonReview eosService={eosService} setEosService={setEosService} />
					</Feature>
				</>
			)}
		</div>
	);
};

export default ServiceView;
