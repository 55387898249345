import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import flatten from 'lodash/flatten';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
	GeoJSON,
	ProviderCostField,
	ProviderRetention,
	ProviderRetentionBreakdown,
	ProviderRetentionNames,
	ServiceType,
} from '../interfaces';
import {
	ATLAS_MEXICO_ID,
	BRANCH,
	BRANCH_CODES,
	CLUPP_MEXICO_ID,
	CRABI_MEXICO_ID,
	HDI_MEXICO_ID,
	KAVAK_MEXICO_ACCOUNT_IDS,
	KAVAK_MEXICO_ID,
	MAX_FILE_SIZE_IN_BYTES,
	ONE_MILE_IN_METERS,
	AFIRME_MEXICO_ID,
	VANTI_ID,
} from './constants';
import ConnectServices from './s3-manager/ConnectServices';

dayjs.extend(utc);
dayjs.extend(timezone);

export const parseObjToQuery = (obj: Record<string, any>): string => {
	const queryParams = new URLSearchParams(obj);
	return `?${queryParams.toString()}`;
};

export const formatPads = (num: number, size: number): string => {
	const s = '000000000' + num;
	return s.substr(s.length - size);
};

interface CheckFileTypeParameters {
	files: File[];
	fileTypes: string[];
}

export interface FileTypeError {
	file: File;
	error: string;
}

/*
	Check if the file type is allowed, return allowed files and errors.
*/
export const checkFileType = ({
	files,
	fileTypes,
}: CheckFileTypeParameters): { allowedFiles: File[]; errors: FileTypeError[] } => {
	const errors: FileTypeError[] = [];
	for (let x = 0; x < files.length; x++) {
		if (fileTypes.every((fileTypes) => files[x].type !== fileTypes)) {
			const error = `File type ${files[x].type} is not allowed.`;
			const file = files[x];
			errors.push({ error, file });
			files = removeFile(files, files[x]);
		} else if (checkMaxFileSize(files[x])) {
			const error = `File size ${files[x].size} is too large.`;
			const file = files[x];
			errors.push({ error, file });
			files = removeFile(files, files[x]);
		}
	}
	return { allowedFiles: files, errors };
};

/* Remove a file from File List */
export const removeFile = (files: File[], file: File): File[] => {
	return Array(...files).filter((f) => f.name !== file.name);
};

// Check max file size in bytes
export const checkMaxFileSize = (file: File, maxFileSize: number = MAX_FILE_SIZE_IN_BYTES): boolean => {
	return file.size >= maxFileSize;
};

/*
	Function to download a pdf file from S3 locally,
	without opening up a new tab and hitting download manually
*/
export const downloadS3Pdf = async (urlFile: string): Promise<void> => {
	const file = await new ConnectServices().getFileBlob(urlFile);
	FileSaver.saveAs(file, 'event-control-document.pdf');
};

export const transformDistance = (meters: number, branch: string): string => {
	const distanceType = branch === BRANCH.PRI ? 'mile' : 'kilometer';
	const divisible = branch === BRANCH.PRI ? ONE_MILE_IN_METERS : 1000;
	const distance = Math.round(meters / divisible);
	return new Intl.NumberFormat('es-PR', {
		style: 'unit',
		unit: distanceType,
		unitDisplay: 'short',
		maximumFractionDigits: 2,
	}).format(distance);
};

export const getExtension = (filename: string): string => {
	return filename.split('.')?.pop() ?? '';
};

export const extractExtension = (filename: string): string => {
	return filename.split('.').slice(0, -1).join('');
};

export const getBranchCurrencyAndLocale = (branch: BRANCH) => {
	switch (branch) {
		case BRANCH.COL:
			return { currency: 'COP', locale: 'es-CO' };
		case BRANCH.CRC:
			return { currency: 'CRC', locale: 'es-CR' };
		case BRANCH.MEX:
			return { currency: 'MXN', locale: 'es-MX' };
		case BRANCH.PAN:
		case BRANCH.PRI:
		default:
			return { currency: 'USD', locale: 'en-US' };
	}
};

export const getBranchCurrencySymbol = (branch: BRANCH) => {
	switch (branch) {
		case BRANCH.CRC:
			return '₡';
		default:
			return '$';
	}
};

export const getBranchCurrency = (branch: BRANCH) => {
	switch (branch) {
		case BRANCH.COL:
			return 'COP';
		case BRANCH.CRC:
			return 'CRC';
		case BRANCH.MEX:
			return 'MXN';
		case BRANCH.PAN:
		case BRANCH.PRI:
		default:
			return 'USD';
	}
};

export const centsToCurrency = (cents: number, branch: BRANCH, showCurrencySymbol = true) => {
	const amount = cents / 100;
	return currencyFormat(amount, branch, showCurrencySymbol);
};

export const currencyFormat = (amount: number, branch: BRANCH, showCurrencySymbol = true) => {
	let options: Intl.NumberFormatOptions = {};

	if (showCurrencySymbol) {
		const currency = getBranchCurrency(branch);
		options = {
			style: 'currency',
			currency,
			currencyDisplay: 'narrowSymbol',
		};
	}
	return new Intl.NumberFormat('es-PR', options).format(amount);
};

export const transformAmountWithCurrency = (amount: number, branch: BRANCH) => {
	const currency = getBranchCurrency(branch);
	const options: Intl.NumberFormatOptions = {
		style: 'currency',
		currency,
		currencyDisplay: 'narrowSymbol',
	};
	return new Intl.NumberFormat(undefined, options).format(amount);
};

export const formatDateTime = (date: Date, locale = 'en') => {
	return new Intl.DateTimeFormat(locale, { dateStyle: 'full', timeStyle: 'short' }).format(date);
};

export const getSituationType = (situation: string, situationTypes: ServiceType[]): string => {
	const currentSituation = situationTypes.find((situationType) => situationType.name === situation);
	return String(currentSituation?.label);
};

export const getSituationTooltip = (situation: string, situationTypes: ServiceType[]): string => {
	return getSituationType(situation, situationTypes);
};

export const transformToCents = (value: number): number => {
	return Math.round(value * 100);
};

export const transformToDecimals = (value: number): number => {
	return value / 100;
};

/**
 * Populates year options dynamically with years from 1990 until next year from now
 */
export const getYearOptions = (): string[] => {
	const nextYear = new Date().getFullYear() + 1;
	const oldestYear = 1990;
	const years: string[] = [];
	for (let year = nextYear; year >= oldestYear; year--) {
		years.push(String(year));
	}
	return years;
};

export const getServiceLayoutSection = (account: string): 'service-kavak' | 'service-vanti' | 'service' => {
	let serviceLayoutSection: 'service-kavak' | 'service-vanti' | 'service' = 'service';

	if (KAVAK_MEXICO_ACCOUNT_IDS.includes(account)) {
		serviceLayoutSection = 'service-kavak';
	} else if (account === VANTI_ID) {
		serviceLayoutSection = 'service-vanti';
	}
	return serviceLayoutSection;
};

export const filterServiceTypesByAccount = ({
	serviceTypes,
	accountId,
	accountOtherServicesAllowed,
}: {
	serviceTypes: ServiceType[];
	accountId: string;
	accountOtherServicesAllowed: string[];
}): ServiceType[] => {
	console.log(serviceTypes);
	accountId = KAVAK_MEXICO_ACCOUNT_IDS.includes(accountId) ? KAVAK_MEXICO_ID : accountId;
	const serviceTypesAllowed =
		SERVICE_TYPES_MX_BY_ACCOUNT[accountId] || SERVICE_TYPES_COL_BY_ACCOUNT[accountId] || accountOtherServicesAllowed;
	return serviceTypes.filter((serviceType) => serviceTypesAllowed.includes(serviceType.name));
};

const SERVICE_TYPES_COL_BY_ACCOUNT: { [key in string]: string[] } = {
	[VANTI_ID]: [
		'electricity',
		'home_locksmith',
		'plumbing',
		'handyman',
		'limpieza_de_estufas',
		'telemedicina',
		'gas-appliance-installation',
	],
};

/**
 * Vamos a mantener este filtro hardcoded por cuenta hasta tener un unico campo en account
 * que nos pueda brindar esta data
 */
const SERVICE_TYPES_MX_BY_ACCOUNT: { [key in string]: string[] } = {
	[KAVAK_MEXICO_ID]: ['grua_transporte', 'towBreakdown', 'towExtraction', 'towTire', 'jumpStart'],
	[ATLAS_MEXICO_ID]: ['towCollision', 'towBreakdown', 'towTire', 'jumpStart', 'flatTire', 'fuelDelivery'],
	[CLUPP_MEXICO_ID]: ['towBreakdown', 'towTire', 'towExtraction', 'fuelDelivery', 'flatTire', 'locksmith'],
	[CRABI_MEXICO_ID]: [
		'towCollision',
		'towBreakdown',
		'towExtraction',
		'towTire',
		'jumpStart',
		'flatTire',
		'fuelDelivery',
		'locksmith',
	],
	[HDI_MEXICO_ID]: [
		'towBreakdown',
		'jumpStart',
		'flatTire',
		'fuelDelivery',
		'locksmith',
		'trip_continuation',
		'grua_ebriedad_local',
		'towCollision',
	],
	[AFIRME_MEXICO_ID]: ['towBreakdown', 'flatTire', 'fuelDelivery', 'locksmith', 'jumpStart'],
};

const SERVICE_TYPE_LABEL_OVERWRITES: { [key in string]: string } = {
	towTire: 'Remolque Llanta',
};

export const getServiceTypeLabel = (serviceType: ServiceType): string => {
	return (
		SERVICE_TYPE_LABEL_OVERWRITES[serviceType.name] || serviceType.label_es || serviceType.label || serviceType.name
	);
};

export const geoJsonToLatLngLiteral = (geoJSON: GeoJSON): google.maps.LatLngLiteral => {
	const [lng, lat] = geoJSON.coordinates;
	return { lat, lng };
};

export const ProviderCostFields: ProviderCostField[] = [
	{ placeholder: 'Wait', fieldName: 'wait' },
	{ placeholder: 'Lock', fieldName: 'lock' },
	{ placeholder: 'Fuel', fieldName: 'fuel' },
	{ placeholder: 'Ferry', fieldName: 'ferry' },
	{ placeholder: 'Toll', fieldName: 'toll' },
	{ placeholder: 'Parking', fieldName: 'parking' },
	{ placeholder: 'Other cost', fieldName: 'otherCost' },
	{ placeholder: "Dolly's", fieldName: 'dollys' },
	{ placeholder: 'Desparqueo', fieldName: 'desparqueo' },
	{ placeholder: 'Patines', fieldName: 'patines' },
	{ placeholder: 'Maniobra', fieldName: 'maniobra' },
	{ placeholder: 'Night Fee', fieldName: 'nightFee' },
];

export const isDateInPastOrEarlierPeriod = (dateString: Date): boolean => {
	if (!dateString) {
		return false;
	}
	const currentDate = dayjs();
	const parsedDate = dayjs.utc(dateString).local();

	const lastMonth = currentDate.subtract(1, 'month').endOf('month');
	if (currentDate.date() <= 15) {
		return parsedDate.isBefore(lastMonth);
	} else {
		return parsedDate.isBefore(currentDate.date(16));
	}
};

export const getRetentionValueByName = (retentions?: ProviderRetention[], name?: string): number => {
	if (!retentions || !name) return 0;
	return retentions.find((retention) => retention.retentionName === name)?.retentionValue ?? 0;
};

export const getRetentionsByBranch = (branch: BRANCH) => {
	switch (branch) {
		case BRANCH.MEX:
			return [ProviderRetentionNames.SITUATION_RETENTION, ProviderRetentionNames.PROVIDER_RETENTION];
		case BRANCH.PRI:
			return [ProviderRetentionNames.PROVIDER_TECH_RETENTION];
		default:
			return [];
	}
};

export const retentionLabels = {
	[ProviderRetentionNames.PROVIDER_RETENTION]: 'Retention PP',
	[ProviderRetentionNames.SITUATION_RETENTION]: 'Retention Situation',
	[ProviderRetentionNames.PROVIDER_TECH_RETENTION]: 'Retention Tech',
};

export const getProviderRetentionBreakdown = (
	serviceProviderRetentions?: ProviderRetention[][] | ProviderRetention[],
	branch?: BRANCH
): Record<ProviderRetentionNames, ProviderRetentionBreakdown> | null => {
	if (!serviceProviderRetentions || !branch) {
		return null;
	}
	// Flatten the array of arrays if needed
	const allRetentions = Array.isArray(serviceProviderRetentions)
		? flatten(serviceProviderRetentions)
		: serviceProviderRetentions;

	// Group by retention name
	const groupedRetentions = groupBy(allRetentions, 'retentionName');

	// Use getRetentionsByBranch to get the retentions for the specified branch
	const branchRetentions: ProviderRetentionNames[] = getRetentionsByBranch(branch);

	// Get all possible retentions and set their initial values to zero
	const allRetentionsMap: Record<ProviderRetentionNames, ProviderRetentionBreakdown> = branchRetentions.reduce(
		(map, retentionName) => {
			map[retentionName] = {
				name: retentionName,
				label: retentionLabels[retentionName],
				value: 0,
				currencyValue: centsToCurrency(0, branch),
			};
			return map;
		},
		{} as Record<ProviderRetentionNames, ProviderRetentionBreakdown>
	);

	// Update retention values if they exist in the grouped retentions
	Object.entries(groupedRetentions).forEach(([retentionName, retentions]) => {
		if (allRetentionsMap[retentionName as ProviderRetentionNames]) {
			const totalRetentionValue = sumBy(retentions, 'retentionValue');
			allRetentionsMap[retentionName as ProviderRetentionNames].currencyValue = centsToCurrency(
				totalRetentionValue,
				branch
			);
			allRetentionsMap[retentionName as ProviderRetentionNames].value = totalRetentionValue;
		}
	});

	// Return an array of retentions
	return allRetentionsMap;
};

export const getBranchFromCountryCode = (countryCode: string): BRANCH => {
	const branch = Object.keys(BRANCH_CODES).find(
		(key) => BRANCH_CODES[key as keyof typeof BRANCH_CODES] === countryCode
	);
	return branch as BRANCH;
};

export const getIVALabel = (branch: BRANCH): string => {
	switch (branch) {
		case BRANCH.PAN:
			return 'ITBMS';
		default:
			return 'IVA';
	}
};
