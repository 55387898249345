import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import map from 'lodash/map';
import EosClient from '../../api/EosClient';
import {
	AppContext,
	EosService,
	Invoice,
	InvoiceAttachmentI,
	ProviderRetentionBreakdown,
	ProviderRetentionNames,
} from '../../interfaces';
import { Context } from '../../context/Context';
import {
	centsToCurrency,
	formatPads,
	getIVALabel,
	getProviderRetentionBreakdown,
	getRetentionsByBranch,
	getRetentionValueByName,
	getSituationType,
	retentionLabels,
} from '../../utils/Utils';
import { BRANCH, BRANCH_CODES, EOS_STATUS } from '../../utils/constants';

import Modal from '../Commons/Modal';
import Feature from '../../utils/FeatureFlags';
import RejectInvoiceModal from './RejectInvoiceModal';
import ConfirmInvoiceActionModal from './ConfirmInvoiceActionModal';
import InvoiceAttachment from './components/InvoiceAttachments';
import { distanceFormat } from '../../views/Service/Service.util';
import { FEATURE_FLAG } from '../../utils/FeatureFlags/constants';
import { ReactComponent as GoBackLogo } from '../../assets/imgs/go-back.svg';
import { ReactComponent as SendEmailLogo } from '../../assets/imgs/send-email.svg';
import './InvoiceDetails.scss';

interface InvoiceDetailsProps {
	invoiceId: string;
	dismiss: () => void;
}

const INVOICE_COLORS: any = {
	Pending: 'pending',
	Rejected: 'rejected',
	Validated: 'validated',
	toPayment: 'payment',
};

function calcPaymentTotal(services: EosService[]): number {
	return services.reduce((acc: number, curr: any) => acc + curr.providerPaymentTotal, 0);
}

function calcPaymentSubTotal(services: EosService[]): number {
	return services.reduce((acc: number, curr: any) => acc + curr.providerPaymentSubTotal, 0);
}

function calcPaymentRetentionTotal(services: EosService[]): number {
	return services.reduce((acc: number, curr: any) => acc + curr.providerPaymentRetention, 0);
}

function calcPaymentTaxTotal(services: EosService[]): number {
	return services.reduce((acc: number, curr: any) => acc + curr.providerPaymentTax, 0);
}

const InvoiceDetails: FunctionComponent<InvoiceDetailsProps> = ({ invoiceId, dismiss }): JSX.Element => {
	const [invoice, setInvoice] = useState<Invoice | any>();
	const { provider, user, situationTypes } = useContext(Context) as AppContext;

	const [branch] = useState<BRANCH>((provider?.branch as BRANCH) || (user?.branch as BRANCH));
	const [invoiceReason, setInvoiceReason] = useState('');

	const [rejectInvoiceIsSubmitting, setRejectInvoiceIsSubmitting] = useState(false);
	const [payInvoiceIsSubmitting, setPayInvoiceIsSubmitting] = useState(false);
	const [isSendingInvoiceEmail, setIsSendingInvoiceEmail] = useState(false);
	const [loading, setLoading] = useState(true);

	const [showRejectInvoiceModal, setShowRejectInvoiceModal] = useState(false);
	const [showConfirmPayInvoiceModal, setShowConfirmPayInvoiceModal] = useState(false);
	const setCancelModal = useState(false)[1];
	const [paymentsTotal, setPaymentsTotal] = useState(0);
	const [paymentsSubTotal, setPaymentsSubTotal] = useState(0);
	const [paymentsTaxTotal, setPaymentsTaxTotal] = useState(0);
	const [servicesTotal, setServicesTotal] = useState(0);
	const [providerRetentionBreakdown, setProviderRetentionBreakdown] = useState<Record<
		ProviderRetentionNames,
		ProviderRetentionBreakdown
	> | null>();
	const [providerRetentionTotal, setProviderRetentionTotal] = useState(0);
	const [reloadInvoiceAttachments, setReloadInvoiceAttachments] = useState(false); // Using during upload/remove a new attachment on created invoice
	const [invoiceAttachments, setInvoiceAttachments] = useState<Array<InvoiceAttachmentI>>();

	useEffect(() => {
		fetchAndSetInvoce();
	}, [invoiceId]);

	useEffect(() => {
		fetchInvoiceAttachments();
	}, [reloadInvoiceAttachments]);

	const fetchAndSetInvoce = async (): Promise<void> => {
		setLoading(true);
		const invoice = await fetchInvoice();
		if (invoice?.status === EOS_STATUS.PENDING) {
			// Filtering rejected services from data if the invoice status is pending.
			invoice.service = invoice.service.filter((service: EosService) => service.eosStatus !== EOS_STATUS.REJECTED);
		} else if (invoice?.status === EOS_STATUS.REJECTED) {
			invoice.service = map(invoice.rejectedInvoiceService, 'service');
		}
		if (invoice && invoice?.service) {
			setPaymentsTotal(calcPaymentTotal(invoice.service));
			setPaymentsSubTotal(calcPaymentSubTotal(invoice.service));
			setPaymentsTaxTotal(calcPaymentTaxTotal(invoice.service));
			setServicesTotal(invoice?.service?.length);
			setInvoiceAttachments(invoice.invoiceAttachments);
			setInvoice(invoice);

			setProviderRetentionTotal(calcPaymentRetentionTotal(invoice.service));
			const providerRetentions = invoice.service.flatMap((service) => service.providerRetentions ?? []);
			setProviderRetentionBreakdown(getProviderRetentionBreakdown(providerRetentions, invoice.branch as BRANCH));
		}
		setLoading(false);
	};

	const fetchInvoiceAttachments = async (): Promise<void> => {
		const invoice = await fetchInvoice();
		if (invoice) setInvoiceAttachments(invoice.invoiceAttachments);
	};

	const fetchInvoice = async (): Promise<Invoice | null> => {
		if (invoiceId) {
			const {
				data: { data },
			} = await new EosClient().getEosInvoice(invoiceId);
			return data;
		} else return null;
	};

	const handleRejectInvoiceSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault();
		setRejectInvoiceIsSubmitting(true);

		try {
			const response = await new EosClient().upsertEosInvoice({
				invoiceId: invoice.id,
				services: invoice.service.map((service: { serviceNumber: number }) => service.serviceNumber),
				reason: invoiceReason,
				providerEmail: invoice.providerEmail,
				provider,
				user,
			});
			if (response.status === 200) {
				await fetchInvoice();
				toast.success('Invoice successfully rejected');
			} else {
				toast.error('Something went wrong, please try again');
			}
		} catch (e) {
			toast.error('Something went wrong, please try again');
		}

		setRejectInvoiceIsSubmitting(false);
		setShowRejectInvoiceModal(false);
		setInvoiceReason('');
		dismiss();
	};

	const handlePayInvoiceSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		e.preventDefault();
		setPayInvoiceIsSubmitting(true);
		try {
			await new EosClient().upsertEosInvoicePayment({
				invoiceIds: [invoice.id],
				services: invoice.service.map((service: { serviceNumber: number }) => service.serviceNumber),
				user,
			});

			await fetchInvoice();
			toast.success(`Invoice #${invoice.preorderNum} has been successfully paid`);
			setShowConfirmPayInvoiceModal(false);
			dismiss();
		} catch (e) {
			toast.error('Something went wrong, please try again');
		} finally {
			setPayInvoiceIsSubmitting(false);
		}
	};

	const handleSendInvoiceEmail = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
		e.preventDefault();
		setIsSendingInvoiceEmail(true);
		try {
			const response = await new EosClient().sendInvoiceEmail(invoice.id, {
				email: provider ? provider.email : user.email,
				preorderNum: invoice.preorderNum,
				branch: provider ? provider.branch : user.branch,
			});
			if (response.status === 200) {
				toast.success(`Invoice #${invoice.preorderNum} has been sent via email`);
			} else {
				toast.error('Something went wrong, please try again');
			}
		} catch (error) {
			console.error(error);
		}
		setIsSendingInvoiceEmail(false);
	};

	const getProviderRetention = (service: EosService, type: ProviderRetentionNames): number => {
		return getRetentionValueByName(service.providerRetentions, type);
	};

	return (
		<div className="invoice">
			{loading && (
				<div className="spinner-container">
					<span className="spinner-border"></span>
				</div>
			)}
			{!loading && invoice && (
				<>
					<div>
						<div className="invoice-header">
							<div className="invoice-header__left-container">
								<button className="invoice-header__go-back-btn" onClick={dismiss}>
									<GoBackLogo className="go-back-svg" />
									<p>Dashboard</p>
								</button>
								<div className="invoice-details">
									<div className="invoice-numbers">
										<p>Invoice #{formatPads(invoice.preorderNum, 6)}</p>
										<p>External Invoice #{invoice.invoiceNum ? formatPads(invoice?.invoiceNum, 6) : ''}</p>
									</div>
									<div className="invoice-badges">
										<div className="invoice-badge invoice-badge__amount-services">
											{`${servicesTotal} ${servicesTotal === 1 ? ' event' : ' events'}`}
										</div>
										<div className={`invoice-badge invoice-badge__invoice-status ${INVOICE_COLORS[invoice.status]}`}>
											{invoice.status}
										</div>
									</div>
								</div>
							</div>
							<Button
								className="invoice-header__send-email-btn"
								onClick={handleSendInvoiceEmail}
								disabled={isSendingInvoiceEmail}
								tooltip={provider ? provider.email : user.email}
								tooltipOptions={{ position: 'top' }}
							>
								<SendEmailLogo className="send-email-svg" />
								<p>Send to my email</p>
							</Button>
						</div>
						<hr className="header-body-division" />

						<div className="invoice-body">
							<InvoiceAttachment
								invoiceId={invoice.id}
								invoiceAttachments={invoiceAttachments}
								branch={invoice.branch}
								reloadInvoiceAttachments={() => setReloadInvoiceAttachments(!reloadInvoiceAttachments)}
							/>
							{invoice.status === EOS_STATUS.REJECTED && <h6>Rejected Reason</h6>}
							{invoice.status === EOS_STATUS.REJECTED && (
								<div className="rejected-details">
									<div className="rejected-details__text">
										<p>{invoice?.rejectedInvoiceService?.[0]?.reason?.reason ?? 'No rejected reason found.'}</p>
									</div>
								</div>
							)}
							<h6>Services</h6>
							<div className="invoice-table">
								<div className="invoice-table__header">
									<h6>PO Number</h6>
									<h6>Driver</h6>
									<h6>Situation</h6>
									<h6>PS Distance</h6>
									<h6>SD Distance</h6>
									<h6>Subtotal</h6>
									<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
										{getRetentionsByBranch(branch).map((retention) => (
											<h6 key={retention}>{retentionLabels[retention]}</h6>
										))}
									</Feature>
									<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
										<h6>Retention</h6>
									</Feature>
									<h6>{getIVALabel(branch)}</h6>
									<h6>Total</h6>
								</div>
								<div className="invoice-table__body invoice__scrollbar">
									{invoice.service?.map((service: EosService) => (
										<div key={service.id} className="invoice-table__row">
											<span>
												<b>{service.serviceNumber}</b>
											</span>
											<span>
												<b>{service.driverName}</b>
											</span>
											<span>
												<b>{getSituationType(service.situation, situationTypes)}</b>
											</span>
											<span>{distanceFormat(service.countryCode, service.providerDistance)}</span>
											<span>{distanceFormat(service.countryCode, service.situationDistance)}</span>
											<span>
												{service.countryCode === BRANCH_CODES['Costa Rica'] ? '₡' : '$'}
												{(service.providerPaymentSubTotal / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
													'0.00'}
											</span>
											<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
												{getRetentionsByBranch(branch).map((retention) => (
													<span key={retention}>
														{
															getProviderRetentionBreakdown(service.providerRetentions, branch)?.[retention]
																.currencyValue
														}
													</span>
												))}
											</Feature>
											<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
												<span>
													{service.countryCode === BRANCH_CODES['Costa Rica'] ? '₡' : '$'}
													{((service.providerPaymentRetention || 0) / 100)
														.toFixed(2)
														.replace(/\d(?=(\d{3})+\.)/g, '$&,') || '0.00'}
												</span>
											</Feature>
											<span>
												{service.countryCode === BRANCH_CODES['Costa Rica'] ? '₡' : '$'}
												{((service.providerPaymentTax || 0) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
													'0.00'}
											</span>
											<span>
												<b>
													{service.countryCode === BRANCH_CODES['Costa Rica'] ? '₡' : '$'}
													{(service.providerPaymentTotal / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
														'0.00'}
												</b>
											</span>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="invoice-footer">
						<div className="invoice-footer__btns">
							{invoice.status === EOS_STATUS.PENDING && (
								<>
									<Feature name={FEATURE_FLAG.INVOICE_DETAIL__PAY_BUTTON}>
										<button
											className="invoice-footer__btn invoice-footer__approve-btn"
											onClick={() => setShowConfirmPayInvoiceModal(true)}
										>
											Paid
										</button>
									</Feature>
									<Feature name={FEATURE_FLAG.INVOICE_DETAIL__REJECT_BUTTON}>
										<button
											className="invoice-footer__btn invoice-footer__reject-btn"
											onClick={() => setShowRejectInvoiceModal(true)}
										>
											Reject Invoice
										</button>
									</Feature>
								</>
							)}
						</div>
						<div className="invoice-footer__amounts">
							<table>
								<tr>
									<td>Sub total:</td>
									<td>{centsToCurrency(paymentsSubTotal, invoice.branch)}</td>
								</tr>
								<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_BREAKDOWN}>
									{getRetentionsByBranch(branch).map((retention) => (
										<tr key={retention}>
											<td>{providerRetentionBreakdown?.[retention].label}:</td>
											<td>
												{providerRetentionBreakdown?.[retention]?.value || 0 ? '-' : ''}
												{providerRetentionBreakdown?.[retention].currencyValue}
											</td>
										</tr>
									))}
								</Feature>
								<Feature name={FEATURE_FLAG.DASHBOARD_BILLING__SHOW_RETENTION_TOTAL}>
									<tr>
										<td>Retention:</td>
										<td>
											{providerRetentionTotal > 0 ? '-' : ''}
											{centsToCurrency(providerRetentionTotal, invoice.branch)}
										</td>
									</tr>
								</Feature>
								<tr>
									<td>{getIVALabel(branch)}:</td>
									<td>{centsToCurrency(paymentsTaxTotal, invoice.branch)}</td>
								</tr>
								{invoice.refundTotal ? (
									<tr>
										<td>Exención Retencion PP:</td>
										<td>{centsToCurrency(invoice.refundTotal, invoice.branch)}</td>
									</tr>
								) : null}
								<tr className="invoice-footer__amounts-total">
									<td>Total:</td>
									<td>{centsToCurrency(invoice.paymentsTotal, invoice.branch)}</td>
								</tr>
							</table>
						</div>
					</div>
					{showConfirmPayInvoiceModal && (
						<Modal
							dismiss={(): void => {
								setCancelModal(false);
							}}
						>
							<ConfirmInvoiceActionModal
								title={`Do you want to pay invoice #${invoice.preorderNum}?`}
								okText="Yes, pay this invoice"
								onSubmit={handlePayInvoiceSubmit}
								onCancelButtonClick={(): void => setShowConfirmPayInvoiceModal(false)}
								isSubmitting={payInvoiceIsSubmitting}
							/>
						</Modal>
					)}
					{showRejectInvoiceModal && (
						<Modal
							dismiss={(): void => {
								setCancelModal(false);
							}}
						>
							<RejectInvoiceModal
								onSubmit={handleRejectInvoiceSubmit}
								onTextareaChange={(e) => setInvoiceReason(e.target.value)}
								onCancelButtonClick={(): void => setShowRejectInvoiceModal(false)}
								rejectReason={invoiceReason}
								isSubmitting={rejectInvoiceIsSubmitting}
							/>
						</Modal>
					)}
				</>
			)}
		</div>
	);
};

export default InvoiceDetails;
